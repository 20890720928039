<template>
    <v-container>
        <v-img
                src="../assets/ekos1.jpg"
                contain
                max-height="200"
                position="center center"
                class="my-3"
        ></v-img>
        <v-app-bar class="grey lighten-3">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn text v-on="on" class="hidden-md-and-up">
                            <v-icon class="blue--text text--darken-4" left>mdi-chevron-down</v-icon>
                            <span class="blue--text text--darken-4">Menüü</span>
                        </v-btn>
                    </template>

                <v-list>
                    <v-list-item
                            v-for="(link,idx) in links"
                            :key="idx"
                            :to="link.route"
                    >
                        <v-list-item-title class="blue--text text--darken-4">{{ link.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn color="#E0E0E0" class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/">Pealeht</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/seltsist">Seltsist</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/lingid">Kasulikud lingid</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/uritused">Üritused</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/kontakt">Kontakt</v-btn>
            <v-spacer></v-spacer>

            <v-btn icon class="mr-2 blue--text text--darken-4" href="https://www.facebook.com/onkoloogia" target="_blank">
                <v-icon large>mdi-facebook</v-icon>
            </v-btn>

        </v-app-bar>

        <v-col>
                <v-img
                        src="../assets/banner24.jpg"
                        contain
                        position="center center"
                        class="my-5">
                </v-img>
                <v-col>
                    <v-card-text class="text-h6 text-center">Eesti Kliiniliste Onkoloogide Selts ja Tartu Ülikooli kliinilise meditsiini instituut kutsuvad teid osalema konverentsil</v-card-text>
                </v-col>
            <v-col>
                    <v-card-text class="text-h2 text-center mt-6 mb-5 hidden-sm-and-down blue--text text--darken-4"> IV staadiumi metastaatiline vähk – ka see on ravitav!</v-card-text>
                    <v-card-text class="text-h4 text-center mt-6 mb-5 hidden-md-and-up blue--text text--darken-4"> IV staadiumi metastaatiline vähk – ka see on ravitav!</v-card-text>
                    <v-card-text class="text-h6 text-center">25. oktoobril 2024 Tartu Ülikooli Raamatukogu konverentsikeskuses.</v-card-text>
            </v-col>
            <v-col>
                    <v-card-text class="text-h6 text-center text--darken-1">EKOS ja TÜ KMI HO kliinik on alates 2014. aastast korraldanud konverentse, mille eesmärk on pakkuda ülevaadet onkoloogiliste haiguste uuematest teadusuuringutest ja ravivõimalustest.</v-card-text>
                    <v-card-text class="text-h6 text-center text--darken-1">Tänavuse konverentsi fookuses on IV staadiumi metastaatiline vähk, mille uued ja efektiivsed ravivõimalused on oluliselt muutnud haigete käsitlust. Tulemusliku raviga on märkimisväärselt paranenud haigete prognoos ning pikenenud eluiga, mistõttu üha enam ei ole esiplaanil patsiendi pahaloomuline kasvaja, vaid muud kaasuvad haigused ja probleemid. </v-card-text>
                    <v-card-text class="text-h6 text-center text--darken-1">Kavas on ettekanded Tartu Ülikooli Kliinikumi spetsialistidelt, kes selgitavad IV staadiumiga vähipatsientide tänast käsitlust ning jagavad uusimaid teadmisi ja praktilisi kogemusi edasiseks erialade vaheliseks koostööks.</v-card-text>
                    <v-card-text class="text-h6 text-center text--darken-1">Programmis on kolm teaduslikku sessiooni, mille eesmärk on pakkuda arutelu ja süvaanalüüsi meedikutele. Neljas sessioon keskendub lisaks ka patsientide ja nende lähedaste teavitamisele ning toetamisele.</v-card-text>
                    <v-card-text class="text-h6 text-center text--darken-1">Konverentsi sihtrühmaks on perearstid, onkoloogid, uroloogid, kirurgid, sisearstid, kõikide erialade residendid, onkoloogiaõed ja teised valdkonnaga seotud meditsiinitöötajad. </v-card-text>
            </v-col>
            
                <v-col>
                    <v-card-text class="text-h6 text-center">Registreeru konverentsile:</v-card-text>
                    <v-card-text class="text-h5 text-center"><a class="blue--text text--darken-4" href="https://onkoloogiakonverents.publicon.ee/registreerimine/" target="_blank">SIIN</a></v-card-text>
                    <v-card-text class="text-h6 text-center">Tutvu programmi ja ajakavaga:</v-card-text>
                    <v-card-text class="text-h5 text-center"><a class="blue--text text--darken-4" href="https://onkoloogiakonverents.publicon.ee/programm/" target="_blank">SIIN</a></v-card-text>
                </v-col>
           
        </v-col>
        <!--
        <v-col>
            <v-card-text class="text-h3 blue--text text--darken-4"> </v-card-text>
            <v-col>
                <v-card-text class="text-h2 text-center mt-6 mb-5 hidden-sm-and-down blue--text text--darken-4">Naised ja vähk, 22.10.2021</v-card-text>
                <v-card-text class="text-h4 text-center mt-6 mb-5 hidden-md-and-up blue--text text--darken-4">Naised ja vähk, 22.10.2021</v-card-text>
                <v-card-text class="text-h6 grey--text text--darken-1">Sarnaselt muule maailmale kasvab Eestis pidevalt nende inimeste hulk, kes mõnel oma eluetapil saavad pahaloomulise kasvaja diagnoosi. <b class="font-weight-bold black--text">Aastal 2018 sai sellise diagnoosi 8783 Eesti inimest, kellest 4321 (49%) olid naised</b>.</v-card-text>
                <v-card-text class="text-h6 grey--text text--darken-1">Vähitõrjes on tähtis osa nii sõeluuringutel kui ka pahaloomuliste kasvajate õigeaegsel diagnoosimisel. Sõeluuringuid viiakse läbi haigustunnusteta ehk asümptomaatilisel elanikkonnal ning nende abil on võimalik diagnoosida vähki varases staadiumis, samuti diagnoosida ja ravida vähieelseid seisundeid.<b class="font-weight-bold black--text"> Õigeaegne diagnoosimine erineb sõeluuringutest, sest hõlmab vähi varast avastamist nendel patsientidel, kellel juba on haigusest tingitud sümptomid. Varane diagnoosimine on sellel haigete rühmal äärmiselt tahtis, sest mida varem vähk avastatakse, seda suurem on tõenäosus tervistavaks raviks ja patsientide paremaks elukvaliteediks.</b> Lisaks eeltoodule aitab vähi õigeaegne diagnostika ja ravi kokku hoida kulutusi levinud haiguse raviks, sest iga järgneva vähistaadiumiga suurenevad kulutused vähiravile.</v-card-text>
                <v-card-text class="text-h6 grey--text text--darken-1">Vähi õigeaegne diagnoosimine hõlmab kolme olulist etappi: (1) <b class="font-weight-bold black--text">inimeste teadlikkus vähi varastest sümptomitest ja pöördumine arstiabi saamiseks;</b> (2) vähi diagnostika, haiguse staadiumi määramine ning (3) vähiravi kättesaadavus. Eeltoodust lähtudes tuleb vähitõrje programmides lisaks erinevate raviaspektide kõrval tähelepanu pöörata nii meetmetele patsientide teadlikkuse parandamiseks kui ka kiireks vähisümptomitega patsientide käsitluseks tervishoiusüsteemis. Just seetõttu on Eesti Kliiniliste Onkoloogide Selts (EKOS) koostöös Tartu Ülikooli Hematoloogia-onkoloogia kliinikuga koostanud patsiendi infomaterjalid, mis käsitlevad naistel kõige sagedamini esinevaid kasvajaid, nende riskitegureid ning võimalikke sümptome.</v-card-text>
                <v-card-text class="text-h6 grey--text text--darken-1">Vähihaigete õigeaegne diagnoosimine ja ravi hõlmab meeskonnatööd, milles on võrdväärne osa nii inimeste enda terviseteadlikkusel kui ka meditsiinipersonali kiirel ja asjakohasel tegutsemisel.</v-card-text>


            </v-col>
            <v-col>
                <v-card-text class="text-h6"><a class="blue--text text--darken-4" href="/EKOS_patsiendi_infomaterjal_rinnavähk.pdf" target="_blank">Infomaterjal - Rinnavähk</a></v-card-text>
                <v-card-text class="text-h6"><a class="blue--text text--darken-4" href="/EKOS_patsiendi_infomaterjal_günekoloogilised_kasvajad.pdf" target="_blank">Infomaterjal - Günekoloogilised Kasvajad</a></v-card-text>
                <v-card-text class="text-h6"><a class="blue--text text--darken-4" href="/EKOS_patsiendi_infomaterjal_naiste_kolorektaalvähk.pdf" target="_blank">Infomaterjal - Naiste Kolorektaalvähk</a></v-card-text>
                <v-card-text class="text-h6"><a class="blue--text text--darken-4" href="/EKOS_patsiendi_infomaterjal_kopsuvähk.pdf" target="_blank">Infomaterjal - Naiste Kopsuvähk</a></v-card-text>
                <v-card-text class="text-h6"><a class="blue--text text--darken-4" href="/EKOS_patsiendi_infomaterjal_naiste_melanoom.pdf" target="_blank">Infomaterjal - Naiste Melanoom</a></v-card-text>
            </v-col>
        </v-col>

            <v-card-text class="text-h2 text-center mt-6 mb-5 hidden-sm-and-down blue--text text--darken-4">Tere tulemast Eesti Kliiniliste Onkoloogide Seltsi (EKOS) veebilehele!</v-card-text>
            <v-card-text class="text-h4 text-center mt-6 mb-5 hidden-md-and-up blue--text text--darken-4">Tere tulemast Eesti Kliiniliste Onkoloogide Seltsi (EKOS) veebilehele!</v-card-text>
                <v-carousel class="my-5" height="600">
                    <v-carousel-item
                            v-for="(item,i) in items"
                            :key="i"
                            :src="item.src"
                    ></v-carousel-item>
                </v-carousel>
                -->
        <template>
            <v-footer padless class="mt-2">
                <v-col
                        class="text-center"
                        cols="12"
                >
                    {{ new Date().getFullYear() }} — <strong>Eesti Kliiniliste Onkoloogide Selts</strong>
                </v-col>
            </v-footer>
        </template>
    </v-container>
</template>

<script>
    import db from '@/fb'

    export default {
        data: () => ({
            links: [
                { text: 'Pealeht', route: '/' },
                { text: 'Seltsist', route: '/seltsist' },
                { text: 'Kasulikud lingid', route: '/lingid' },
                { text: 'Üritused', route: '/uritused' },
                { text: 'Kontakt', route: '/kontakt' },
            ],
            items: [],
        }),
        created(){
            db.collection('pildid').orderBy("id", "asc").onSnapshot(res => {
                const changes = res.docChanges();

                changes.forEach(change => {
                    if (change.type === 'added') {
                        this.items.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                })
            });
        }
    }
</script>

<style>

</style>