<template>
    <v-container>
        <v-img
                src="../assets/ekos1.jpg"
                contain
                max-height="200"
                position="center center"
                class="my-5"
                id="Logopic"
        ></v-img>
        <v-app-bar class="grey lighten-3">

            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" class="hidden-md-and-up">
                        <v-icon class="blue--text text--darken-4" left>mdi-chevron-down</v-icon>
                        <span class="blue--text text--darken-4">Menüü</span>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                            v-for="(link,idx) in links"
                            :key="idx"
                            :to="link.route"
                    >
                        <v-list-item-title class="blue--text text--darken-4">{{ link.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/">Pealeht</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/seltsist">Seltsist</v-btn>
            <v-btn color="#E0E0E0" class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/lingid">Kasulikud lingid</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/uritused">Üritused</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/kontakt">Kontakt</v-btn>
            <v-spacer></v-spacer>

            <v-btn icon class="mr-2 blue--text text--darken-4" href="https://www.facebook.com/onkoloogia" target="_blank">
                <v-icon large>mdi-facebook</v-icon>
            </v-btn>

        </v-app-bar>
        <v-col>
            <v-card-text class="text-h3 blue--text text--darken-4">Kasulikud lingid</v-card-text>
        </v-col>
            <v-col>
                <v-card-text v-for="link in lingid" :key="link.id"><a target="_blank" :href="`${link.link}`" style="font-size: 20px">{{link.nimi}}</a></v-card-text>
            </v-col>
        <template>
            <v-footer padless class="mt-2">
                <v-col
                        class="text-center"
                        cols="12"
                >
                    {{ new Date().getFullYear() }} — <strong>Eesti Kliiniliste Onkoloogide Selts</strong>
                </v-col>
            </v-footer>
        </template>
    </v-container>
</template>

<script>
    import db from '@/fb'
    export default {
        data: () => ({
                links: [
                    { text: 'Pealeht', route: '/' },
                    { text: 'Seltsist', route: '/seltsist' },
                    { text: 'Kasulikud lingid', route: '/lingid' },
                    { text: 'Üritused', route: '/uritused' },
                    { text: 'Kontakt', route: '/kontakt' },
                ],
                lingid:[],

            }
        ),
        created() {
            db.collection('lingid').orderBy("id", "asc").onSnapshot(res => {
                const changes = res.docChanges();

                changes.forEach(change => {
                    if (change.type === 'added') {
                        this.lingid.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                })
            });
        }
    }
</script>

<style scoped>

</style>