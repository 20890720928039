// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyCTYy7PGfuDZ-Ej3KTpOPIByv2ok6x2CMc",
    authDomain: "ekosweb-11c87.firebaseapp.com",
    projectId: "ekosweb-11c87",
    storageBucket: "ekosweb-11c87.appspot.com",
    messagingSenderId: "363329437450",
    appId: "1:363329437450:web:5327efb77e12848ca684f3",
    measurementId: "G-7129833WP3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();

export default db;