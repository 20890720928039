<template>
    <v-container>
        <v-img
                src="../assets/ekos1.jpg"
                contain
                max-height="200"
                position="center center"
                class="my-5"
        ></v-img>
        <v-card-text class="text-center text-h1">Error 404</v-card-text>
        <v-card-actions class="justify-center">
            <v-btn href="/">
                Tagasi pealehele
            </v-btn>
        </v-card-actions>
    </v-container>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>

</style>