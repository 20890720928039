<template>
    <v-container>
        <v-img
                src="../assets/ekos1.jpg"
                contain
                max-height="200"
                position="center center"
                class="my-5"
                id="Logopic"
        ></v-img>
        <v-app-bar class="grey lighten-3">

            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" class="hidden-md-and-up">
                        <v-icon class="blue--text text--darken-4" left>mdi-chevron-down</v-icon>
                        <span class="blue--text text--darken-4">Menüü</span>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                            v-for="(link,idx) in links"
                            :key="idx"
                            :to="link.route"
                    >
                        <v-list-item-title class="blue--text text--darken-4">{{ link.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/">Pealeht</v-btn>
            <v-btn color="#E0E0E0" class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/seltsist">Seltsist</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/lingid">Kasulikud lingid</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/uritused">Üritused</v-btn>
            <v-btn class="ml-4 blue--text text--darken-4 hidden-sm-and-down" href="/kontakt">Kontakt</v-btn>
            <v-spacer></v-spacer>

            <v-btn icon class="mr-2 blue--text text--darken-4" href="https://www.facebook.com/onkoloogia" target="_blank">
                <v-icon large>mdi-facebook</v-icon>
            </v-btn>

        </v-app-bar>

        <v-row justify="center">

            <v-col class="mt-5">
                    <v-tabs centered color="#0D47A1">
                        <v-tabs-slider color="#0D47A1"></v-tabs-slider>

                        <v-tab key="tutvustus">Tutvustus</v-tab>
                        <v-tab key="pohikiri">Põhikiri</v-tab>
                        <v-tab key="tunnustused">Tunnustused</v-tab>
                        <v-tab key="aliikmeks">Astu liikmeks</v-tab>
                        <v-tab key="koostoo">Koostöö</v-tab>


                    <v-tab-item key="tutvustus">
                        <v-card flat>
                            <v-card-text class="text-h6">Eesti Kliiniliste Onkoloogide Selts on sõltumatu, vabatahtlik ühendus, mille peamisteks eesmärkideks on kliinilise onkoloogia (onkoloogia kiiritus- ja keemiaravi tähenduses) ühtse eriala, onkoloogiaalaste teadmiste ning teadustöö edendamine.</v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item key="pohikiri">
                        <v-card flat>
                            <v-expansion-panels multiple>

                                <v-expansion-panel>
                                    <v-expansion-panel-header class="text-h4 blue--text text--darken-4">
                                        Üldsätted
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div>
                                            <p>
                                                <strong>Eesti Kliiniliste Onkoloogide Selts</strong>, lühendatult EKOS<br>
                                                <strong>Estonian Society of Clinical Oncologists</strong>, lühendatult ESCO<br>
                                                Eesti Kliiniliste Onkoloogide Selts (edaspidi „selts“) on sõltumatu, vabatahtlik, mittetulunduslik ühendus, mis juhindub oma tegevuses Eesti Vabariigi seadustest ja seltsi põhikirjast.
                                                Eesti Kliiniliste Onkoloogide Selts asub Tartus.
                                            </p>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header class="text-h4 blue--text text--darken-4">
                                        Eesmärk
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div>
                                            <p>Eesti Kliiniliste Onkoloogide Seltsi (edaspidi „selts“) eesmärkideks on:</p>
                                            <ul>
                                                <li>kliinilise onkoloogia (onkoloogia kiiritus- ja keemiaravi tähenduses) ühtse eriala edendamine;</li>
                                                <li>onkoloogiaalaste teadmiste ja teadustöö süsteemipärane edendamine;</li>
                                                <li>onkoloogiast huvitatud arstide omavahelise suhtluse tihendamine ja erialalise informatsiooni vahendamine;</li>
                                                <li>koostöö arendamine teiste erialaseltside ja ühingutega.</li>
                                            </ul>
                                            <br>
                                            <p>Eesmärkide saavutamiseks selts:</p>
                                            <ul>
                                                <li>koondab onkolooge (kiiritus- ja keemiaravi tähenduses) ja teisi füüsilisi ning juriidilisi isikuid, kes aitavad oma tegevusega kaasa seltsi eesmärkide täitmisele;</li>
                                                <li>teeb võrdsetel alustel koostööd teiste erialaseltside ja -ühingute, ravi- ja teadusasutuste ning kõrgkoolidega, kes oma tegevusega võtavad osa seltsi ülesannete täitmisest;</li>
                                                <li>korraldab koosolekuid, seminare, konverentse ning teisi	 teaduslikke ja populaarteaduslikke üritusi;</li>
                                                <li>aitab koostada erialaseid soovitusi ning eksperthinnanguid;</li>
                                                <li>koostab ja annab välja teaduslikku kirjandust, kogumikke ja metoodilisi juhendeid;</li>
                                                <li>tegeleb onkoloogiaalase teadustööga;</li>
                                                <li>annab ekspertnõuandeid;</li>
                                                <li>asutab vajadusel allüksusi.</li>
                                            </ul>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header class="text-h4 blue--text text--darken-4">
                                        Seltsi õigused
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <ul>
                                            <li>omada ruume, pangakontot ja teha finantsoperatsioone;</li>
                                            <li>sõlmida lepinguid füüsiliste ja juriidiliste isikutega;</li>
                                            <li>anda toetusi, stipendiume ja auhindu vastavalt kehtivale korrale;</li>
                                            <li>esitada kandidaate toetuste, auhindade ning stipendiumite saamiseks;</li>
                                            <li>taotleda toetusi, auhindu ja stipendiume oma liikmetele.</li>
                                        </ul>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header class="text-h4 blue--text text--darken-4">
                                        Liikmed
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <ul>
                                            <li>seltsi täisliikmed võivad olla onkoloogid (kiiritus- ja keemiaravi tähenduses) ning onkoloogia residendid (kiiritus- ja keemiaravi tähenduses), kes täidavad seltsi põhikirja nõudeid;</li>
                                            <li>seltsi toetajaliikmed võivad olla nii füüsilised kui juriidilised isikud, kes osutavad abi seltsi eesmärkide saavutamiseks;</li>
                                            <li>seltsi auliikmeks valitakse silmapaistvaid onkoloogia eriala spetsialiste või teisi isikuid eriliste teenete eest seltsi eesmärkide elluviimisel;</li>
                                            <li>seltsi täisliikmeks võetakse vastu kirjaliku avalduse alusel üldkoosoleku otsusega;</li>
                                            <li>seltsi toetajaliikmeks võetakse vastu seltsi juhatuse otsusega;</li>
                                            <li>seltsi liikmetele liikmemaksu ei ole;</li>
                                            <li>seltsi liikmetel on õigus võtta osa seltsi tegevusest vastavalt seltsi eesmärkidele ja põhikirjale;</li>
                                            <li>seltsi liikmetel on õigus valida ja olla valitud seltsi juhatusse ja/või allüksustesse;</li>
                                            <li>seltsi liikmetel on õigus esitada seltsi juhatusele ettepanekuid ja arupärimisi seltsi tegevuse kohta;</li>
                                            <li>seltsi liikmetel on õigus võtta osa kõigist seltsi poolt korraldatavatest üritustest;</li>
                                            <li>seltsi liikmed on kohustatud järgima seltsi põhikirja ning üldkoosoleku ja seltsi juhatuse otsuseid;</li>
                                            <li>seltsist väljaarvamine toimub juhatuse otsusel liikme kirjaliku avalduse alusel või kui liige ei ole järjestikusel kolmel aastal üldkogu koosolekutest osa võtnud.</li>
                                        </ul>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header class="text-h4 blue--text text--darken-4">
                                        Juhtimine
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <ul>
                                            <li>seltsi kõrgeimaks organiks on üldkoosolek;</li>
                                            <li>seltsi korraline üldkoosolek kutsutakse seltsi juhatuse poolt kokku üks kord aastas;</li>
                                            <li>seltsi juhatus esitab korralisel üldkoosolekul eelneva tegevusaasta aruande;</li>
                                            <li>seltsi üldkoosolek saab vastu võtta otsuseid küsimustes, mis on koosoleku kokkukutsumisel eelnevalt teatavaks tehtud kõikidele seltsi liikmetele vähemalt kaks nädalat enne koosoleku toimumist;</li>
                                            <li>seltsi üldkoosoleku otsus on vastu võetud, kui selle poolt on hääletanud üle poole koosolekust osavõtjatest;</li>
                                            <li>otseselt eriala puudutavates küsimustes (nt õppeprogrammid, eriala arengusuunad jne) saavad hääletada ainult seltsi täisliikmed  ning otsus on vastu võetud, kui selle poolt on hääletanud üle poole koosolekust osavõtvatest onkoloogidest;</li>
                                            <li>seltsi juhatus juhib seltsi tööd üldkoosolekute vahelisel perioodil;</li>
                                            <li>seltsi juhatuses on kuni kolm liiget;</li>
                                            <li>seltsi juhatus valitakse kolmeks aastaks otsesel salajasel hääletusel;</li>
                                            <li>seltsi juhatuse liikmetel on õigus esindada seltsi kõikides õigustoimingutes;</li>
                                            <li>seltsi juhatuse liikme võib üldkoosoleku otsusega tagasi kutsuda kohustuste olulise täitmatajätmise korral või seoses võimetusega seltsi juhtida;</li>
                                            <li>seltsi juhatuse liikmel on õigus taotleda oma ülesannete täitmisega seotud kulutuste hüvitamist vastavalt kehtivale õiguskorrale;</li>
                                            <li>seltsi juhatuse otsused võetakse vastu häälteenamusega, otsuse tegemisel peavad kohal viibima kõik seltsi juhatuse liikmed;</li>
                                            <li>seltsi tegevus lõpetatakse üldkoosoleku otsusega või muudel seadusega ettenähtud viisidel;</li>
                                            <li>kõik seltsi tegevuse lõpetamisega seotud küsimused lahendab seltsi üldkoosoleku poolt moodustatud komisjon;</li>
                                            <li>käesoleva põhikirja muutmise ja täiendamise otsustab üldkoosolek. Otsus on vastu võetud, kui selle poolt on üle 2/3 otsustusvõimelisel üldkoosolekul osalevatest seltsi liikmetest;</li>
                                            <li>põhikirjaga ettenähtud seltsi eesmärgi muutmiseks on vaja vähemalt 9/10 liikmete nõusolek. Põhikirja muutmise ja täiendamise algataja edastab vastavad kirjalikud ettepanekud seltsi liikmetele vähemalt 1 kuu enne üldkoosoleku kokkukutsumist.</li>
                                        </ul>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                            </v-expansion-panels>
                            <v-card-text class="text-h6 blue--text text--darken-4 mt-3">Põhikiri on kinnitatud 06. aprill 2017</v-card-text>
                        </v-card>
                    </v-tab-item>

                        <v-tab-item key="tunnustused">
                            <v-row class="justify-center" justify="center">
                                <v-col v-for="tunnustus in tunnustused" :key="tunnustus.kes" cols="12" md="6">
                                    <v-card flat>
                                        <v-card-text class="text-h4 blue--text text--darken-4">{{tunnustus.kes}}</v-card-text>
                                        <v-card-text class="text-h6">{{tunnustus.miks}}</v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item key="aliikmeks">
                            <v-row class="justify-center ma-6">
                                <v-col>
                                    <v-card elevation="14">
                                        <v-card-text class="text-h2 text-center blue--text text--darken-4">Täisliige</v-card-text>
                                        <v-card-text class="text-center text-h6">Seltsi täisliikmed võivad olla onkoloogid ning onkoloogia residendid, kes täidavad seltsi põhikirja nõudeid.</v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col>
                                    <v-card elevation="14">
                                        <v-card-text class="text-h2 text-center blue--text text--darken-4">Toetajaliige</v-card-text>
                                        <v-card-text class="text-center text-h6">Seltsi toetajaliikmed võivad olla nii füüsilised kui juriidilised isikud, kes osutavad abi seltsi eesmärkide saavutamiseks.</v-card-text>
                                    </v-card>
                                </v-col>

                            </v-row>
                            <v-card-text class="text-h4 grey--text text--darken-1">Soovin astuda Eesti Kliiniliste Onkoloogide Seltsi liikmeks</v-card-text>
                            <v-form v-model="valid" ref="form" @submit.prevent="submit">
                                <v-container>
                                            <v-text-field
                                                    v-model="firstname"
                                                    :rules="nameRules"
                                                    :counter="20"
                                                    label="Eesnimi"
                                                    required
                                            ></v-text-field>
                                            <v-text-field
                                                    v-model="lastname"
                                                    :rules="nameRules"
                                                    :counter="20"
                                                    label="Perekonnanimi"
                                                    required
                                            ></v-text-field>
                                            <v-text-field
                                                    v-model="email"
                                                    :rules="emailRules"
                                                    label="E-mail"
                                                    required
                                            ></v-text-field>
                                    <v-radio-group
                                            v-model="row"
                                            row
                                    >
                                        <v-radio
                                                label="Täisliige"
                                                value="Täisliige"
                                        ></v-radio>
                                        <v-radio
                                                label="Toetajaliige"
                                                value="Toetajaliige"
                                        ></v-radio>
                                    </v-radio-group>
                                    <vue-recaptcha ref="recaptcha"
                                                   @verify="onVerify" sitekey="6LdRmLcaAAAAAOybwQfii4PVp_3scawlxRhA8cCH">
                                    </vue-recaptcha>
                                    <v-btn @click="submit()" class="mt-3">Saada</v-btn>
                                </v-container>
                            </v-form>
                        </v-tab-item>

                        <v-tab-item key="koostoo">
                            <v-row class="justify-center" justify="center">
                                <v-col v-for="item in koostoo" :key="item.firma">
                                    <v-card flat>
                                        <v-card-text class="text-h4 blue--text text--darken-4">{{item.firma}}</v-card-text>
                                        <v-card-text class="text-h6">{{item.info}}</v-card-text>
                                        <v-card-text :hidden="item.link1 == null"><a target="_blank" :href="`${item.link1}`" style="font-size: 15px">{{item.info1}}</a></v-card-text>
                                        <v-card-text :hidden="item.link2 == null"><a target="_blank" :href="`${item.link2}`" style="font-size: 15px">{{item.info2}}</a></v-card-text>
                                        <v-card-text :hidden="item.link3 == null"><a target="_blank" :href="`${item.link3}`" style="font-size: 15px">{{item.info3}}</a></v-card-text>
                                        <v-card-text :hidden="item.link4 == null"><a target="_blank" :href="`${item.link4}`" style="font-size: 15px">{{item.info4}}</a></v-card-text>
                                        <v-card-text :hidden="item.link5 == null"><a target="_blank" :href="`${item.link5}`" style="font-size: 15px">{{item.info5}}</a></v-card-text>
                                        <v-card-text :hidden="item.link6 == null"><a target="_blank" :href="`${item.link6}`" style="font-size: 15px">{{item.info6}}</a></v-card-text>
                                        <v-card-text :hidden="item.link7 == null"><a target="_blank" :href="`${item.link7}`" style="font-size: 15px">{{item.info7}}</a></v-card-text>
                                        <v-card-text :hidden="item.link8 == null"><a target="_blank" :href="`${item.link8}`" style="font-size: 15px">{{item.info8}}</a></v-card-text>
                                        <v-card-text :hidden="item.link9 == null"><a target="_blank" :href="`${item.link9}`" style="font-size: 15px">{{item.info9}}</a></v-card-text>
                                        <v-card-text :hidden="item.link10 == null"><a target="_blank" :href="`${item.link10}`" style="font-size: 15px">{{item.info10}}</a></v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                    </v-tabs>
            </v-col>
            <!--
            <v-col class="mt-5 ml-5 hidden-sm-and-down">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fonkoloogia&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2819482728293175" width="500" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </v-col>

            <v-col class="mt-5 ml-5 hidden-md-and-up">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fonkoloogia&tabs=timeline&width=300&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2819482728293175" width="300" height="400" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </v-col>
            -->

        </v-row>

        <template>
            <v-footer padless class="mt-2">
                <v-col
                        class="text-center"
                        cols="12"
                >
                    {{ new Date().getFullYear() }} — <strong>Eesti Kliiniliste Onkoloogide Selts</strong>
                </v-col>
            </v-footer>
        </template>
    </v-container>
</template>

<script>
    import db from '@/fb'
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        components: {
            VueRecaptcha,
        },
        data: () => ({
                links: [
                    { text: 'Pealeht', route: '/' },
                    { text: 'Seltsist', route: '/seltsist' },
                    { text: 'Kasulikud lingid', route: '/lingid' },
                    { text: 'Üritused', route: '/uritused' },
                    { text: 'Kontakt', route: '/kontakt' },
                ],

            tunnustused: [],
            koostoo: [],
            lingid:[],

            form:{
                robot: false
            },
            text: "",
            valid: false,
            firstname: '',
            lastname: '',
            row: null,
            nameRules: [
                v => !!v || 'Väli ei saa olla tühi',
                v => v.length <= 20 || 'Maksimaalne pikkus on 20 sümbolit'
            ],
            email: '',
            emailRules: [
                v => !!v || 'Väli ei saa olla tühi',
                v => /.+@.+/.test(v) || 'E-mail peab olema korrektne',
                v => v.length <= 40 || 'Maksimaalne pikkus on 40 sümbolit'
            ],
        }),
        methods:{

            submit(){
                if (this.form.robot) {
                if(this.$refs.form.validate()){
                    const testData = {
                        firstname: this.firstname,
                        lastname: this.lastname,
                        email: this.email,
                        row: this.row,
                        to:'onkoloogia@gmail.com',
                        message: {
                            subject: 'Uus liikmesoov',
                            html: 'Eesnimi: ' + this.firstname +'<br>Perenimi: ' + this.lastname + '<br>Email: ' + this.email + '<br>Liikmetüüp: ' + this.row,
                        }
                    };
                    db.collection('test').add(testData).then(() => console.log('form sent'));
                    this.$refs.form.reset();
                }
            }},
            onVerify: function (response) {
                if (response) this.form.robot = true;
            },
        },

        created() {
            db.collection('tunnustused').onSnapshot(res => {
                const changes = res.docChanges();

                changes.forEach(change => {
                    if (change.type === 'added') {
                        this.tunnustused.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                })
            });

            db.collection('koostoo').onSnapshot(res => {
                const changes = res.docChanges();

                changes.forEach(change => {
                    if (change.type === 'added') {
                        this.koostoo.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                    }
                })
            });
        }
    }
</script>

<style scoped>

</style>